<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #keyPoints>
            <ul>
                <li>Рейтинги:     3 место в мире  [Financial Times 2021 – Master in Finance]
                5 место  [L’Etudiant 2021– Master in Management]
                11 место в мире  [QS Ranking 2021 - MSc IMBD)
                </li>
                <li>
                    7  кампусов в 5 странах [Бразилия, Китай, Франция, Южная Африка, США]
                </li>
                <li>
                    Кампусы во Франции: Париж, Лилль, София Антиполис.
                </li>
                <li>
                    +70 программ [Global BBA, Master in Management, Masters of Science, Specialized Masters®, Global Executive MBA, Executive Education, Летние школы, PhD и DBA]
                </li>
                <li>
                    +40 клубов выпускников по всему миру
                </li>
                <li>
                    +70 студенческих клубов в 7 кампусах
                </li>
                <li>
                    +250 проектов в SKEMA Ventures
                </li>
                <li>
                    +120 национальностей в кампусах SKEMA
                </li>
                <li>
                    +140 академических партнерств в более чем 40 странах
                </li>
                <li>
                    +500 профессоров, в том числе 180 членов академического факультета
                </li>
                <li>
                    2500 компаний-партнеров
                </li>
                <li>
                    9 500 студентов
                </li>
                <li>
                    50 000 выпускников в 145 странах
                </li>
            </ul>
        </template>
        <template #description>
            SKEMA Business School - одна из лучших французских бизнес-школ в сфере высшего образования и исследований. Она была основана в 2009 году в результате слияния Высшей школы торговли (ESC), Лилль и Бизнес-школы CERAM, София Антиполис. Школа в Лилле была основана в 1892 году, а CERAM - в 1963 году. SKEMA предлагает такие программы, как BBA (бакалавриат), магистратура (MIM, MS и MSc), EMBA, докторантура и программы обучения руководителей во Франции, Бразилии, Китае и США. 
            Бизнес-школа SKEMA была недавно оценена Financial Times и The Economist как одна из лучших бизнес-школ в континентальной Европе и одна из ведущих бизнес-школ мира.
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/embed/UIXFHQpMEI4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Finance, business, economy
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="baa">BBA in Global Management</option>
                                <option value="mim">Master in Management (MIM)</option>
                                <option value="msc">Msc - Master of Science</option>
                                <option value="sff">Msc - Substainable finance & fintech</option>
                                <option value="cfm">Msc - Corporate Financial Management</option>
                                <option value="fmi">Msc - Financial markets & investments</option>
                                <option value="db">Msc - Digital Business & management</option> 
                                <option value="ppm">Msc - Project and Programme Management </option>
                                <option value="ihr">Msc - International HR </option>
                                <option value="scm">Msc - Supply Chain Management and Purchasing </option>
                                <option value="sem">Msc - Event and Tourism Management  </option>
                                <option value="imb">Msc - International Marketing and Business</option>
                                <option value="lfm">Msc - Luxury and Fashion Management </option>
                                <option value="glm">Msc - Global Luxury and Management </option>
                                <option value="lhi">Msc - Luxury Hospitality and Innovation</option>
                                <option value="dm">Msc - Digital Marketing</option>
                                <option value="ib">Msc - International Business</option>
                                <option value="ei">Msc - Entrepreneurship and Innovation</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/skema/SKEMA_Global_BBA.pdf'" v-if="this.program === 'baa'" id="baa">
                        <template #title>
                            Бакалавриат - BBA in Global Management
                        </template>
                        <template #description>
                            <p>
                                Студенты проходят часть обучения за пределами Франции, в партнерских университетах или в собственных кампусах SKEMA в Бразилии, Китае или США в течение от 12 до 24 месяцев.
                            </p>
                            <p>
                                Возможны программы двойных дипломов с партнерскими университетами США, Англии, Канады, Австралии, Ирландии. 
                            </p>
                        </template>
                        <template #duree>
                            4 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            12.000 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Лилль / София Антиполис / США / Бразилия
                        </template>
                        <template #start-time>
                            сентябрь и январь
                        </template>
                        <template #salary>
                            39.500 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MIM.pdf'" v-if="this.program === 'mim'" id="mim">
                        <template #title>
                            Master in Management (MIM) - Grande Ecole Programme
                        </template>
                        <template #description>
                            <p>
                                Первый год обучения:
                                Студенты программы M1 в SKEMA, будут изучать Advanced Business Management. Это первый год двухлетней магистерской программы, основная цель которой - помочь вам глубже понять принципы управления бизнесом и предоставить вам возможность развивать свой международный опыт.
                            </p>
                            <p>
                                Второй год обучения: 
                                На втором курсе студенты приобретают специализацию. Эта специализация последнего года и стажировка являются ключом к трудоустройству.
                            </p>
                        </template>
                        <template #duree>
                            12-24 месяца
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            15.000 евро/год
                        </template>
                        <template #requirements>
                            английский язык B2, TAGE MAGE или GMAT или GRE или CAT, досье, интервью.
                        </template>
                        <template #campus>
                            Париж/Лилль/ София Антиполис или США, Бразилия, Китай, Южная Африка.
                        </template>
                        <template #start-time>
                            сентябрь и январь
                        </template>
                        <template #salary>
                            45.500 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'msc'" id="msc">
                        <template #title>Master of Science - MSc</template>
                        <template #description>
                            <p>
                                Магистратура (MSc) - это всемирно признанная степень, которую выбирают студенты, которые хотят повысить свои шансы на трудоустройство в международной среде. Магистратура SKEMA предоставляет специализированные знания, ценные на рынке труда. 
                            </p>
                            <p>
                                Одногодичные программы магистратуры предназначены для абитуриентов, имеющих четырехлетнюю степень (бакалавриат 4 года в своей стране).
                                SKEMA Business School предлагает 20 магистерских программ.
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000-25.000 евро/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж/Лилль/ София Антиполис или США, Бразилия, Китай, Южная Африка.
                        </template>
                        <template #start-time>
                            сентябрь и январь
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>
                    
                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'sff'" id="sff">
                        <template #title>Msc - Substainable finance & fintech</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'cfm'" id="cfm">
                        <template #title>Msc - Corporate Financial Management</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                             Париж, София-Антиполис, Бразилия, Китай
                        </template>
                        <template #start-time>
                            Август/Сентябрь, Январь 
                        </template>
                        <template #salary>
                            48.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'fmi'" id="fmi">
                        <template #title>Msc - Financial markets & investments</template>
                        <template #description>
                            <p>
                                Эта магистратура признана одной из лучших в мире (Financial Times: третье место в мире)
                            </p>
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            25.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж, София-Антиполис, США
                        </template>
                        <template #start-time>
                            Август/Сентябрь
                        </template>
                        <template #salary>
                            87.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'db'" id="db">
                        <template #title>Msc - Digital Business, Data Analysis and Management</template>
                        
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            София-Антиполис
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                        <template #salary>
                            52.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'ppm'" id="ppm">
                        <template #title>Msc - Project and Programme Management and Business Development </template>
                        
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж, Лилль, Бразилия
                        </template>
                        <template #start-time>
                            Август/Сентябрь, Январь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'ihr'" id="ihr">
                        <template #title>Msc - International Human Resources and Performance Management </template>
                        
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Сентябрь, Январь
                        </template>
                        <template #salary>
                            40.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'scm'" id="scm">
                        <template #title>Msc - Supply Chain Management and Purchasing</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Лилль
                        </template>
                        <template #start-time>
                            Сентябрь, Январь
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'sem'" id="sem">
                        <template #title>Msc - Strategic Event Management and Tourism Management </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            София Антиполис
                        </template>
                        <template #start-time>
                            Сентябрь, Январь
                        </template>
                        <template #salary>
                            38.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'imb'" id="imb">
                        <template #title>Msc - International Marketing and Business Development </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Лилль, Париж, София Антиполис, Бразилия, Китай
                        </template>
                        <template #start-time>
                            Август/Сентябрь, Январь
                        </template>
                        <template #salary>
                            38.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'lfm'" id="lfm">
                        <template #title>Msc - Luxury and Fashion Management </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            21.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            София Антиполис, Китай
                        </template>
                        <template #start-time>
                            Август/Сентябрь, Январь
                        </template>
                        <template #salary>
                            50.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'glm'" id="glm">
                        <template #title>Msc - Global Luxury and Management</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            25.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            США + Париж
                        </template>
                        <template #start-time>
                            Август
                        </template>
                        <template #salary>
                            53.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'lhi'" id="lhi">
                        <template #title>Msc - Luxury Hospitality and Innovation</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            25.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                        <template #salary>
                            48.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'dm'" id="dm">
                        <template #title>Msc - Digital Marketing </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            София Антиполис
                        </template>
                        <template #start-time>
                            Сентябрь, Январь
                        </template>
                        <template #salary>
                            41.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'ib'" id="ib">
                        <template #title>Msc - International Business</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            Париж, Китай, США, Южная Африка, Бразилия
                        </template>
                        <template #start-time>
                            Август/Сентябрь, Январь
                        </template>
                        <template #salary>
                            45.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/skema/SKEMA_MSc.pdf'" v-if="this.program === 'ei'" id="ei">
                        <template #title>Msc - Entrepreneurship and Innovation</template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            17.000/год
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            София Антиполис
                        </template>
                        <template #start-time>
                            Сентябрь, Январь
                        </template>
                        <template #salary>
                            40.000 евро/год
                        </template>
                    </Program>
                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Lille
                </template>
                <template #description>
                    Кампус бизнес-школы SKEMA в Лилле - это современное здание площадью 20 000 квадратных метров, расположенное в самом сердце делового центра города, в двух шагах от Старого города города и площади Гран-Плас. Расположенный между двумя станциями TGV, он находится в получасе езды от Парижа, в 38 минутах от Брюсселя и в 1 часе 20 минутах от Лондона.
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/UIXFHQpMEI4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Paris
                </template>
                <template #description>
                    Кампус занимает территорию 14 000 м2, в том числе 1 500 м2 - это терраса на крыше с видом на Сену и Эйфелеву башню. После более чем года работ по модернизации и реконструкции, флагманский кампус SKEMA Grand Paris открыл свои двери в январе 2021 года. 
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/e1MMm_utjdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Nice Sophia Antipolis
                </template>
                <template #description>
                    Ультрасовременный кампус SKEMA площадью 30 гектаров гармонично вписывается в динамичное «сообщество знаний», в которое входят более 1300 компаний в таких секторах, как информационные и коммуникационные технологии и биотехнологии. Школа является частью Université Côte d'Azur.
                    Города поблизости: Ницца, Канны, Монако, Сан-Тропе.                 </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/embed/e1MMm_utjdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '../../slots/Program.vue'
import CampusMain from '../../slots/CampusMain.vue'
import SectionsNav from '../../slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'


export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>