<template>
    <Introduction :url="'/brochure/skema'" :class="'introduction-skema'">  
        <template #title>
            SKEMA Business School
        </template>
        <template #speciality>
            Бизнес
        </template>
        <template #min-duration>
            От 1 года
        </template>
        <template #cost>
            От 14000 €/год
        </template>
        <template #campus>
            Париж, Лилль, София Антиполис
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '../../slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>